<template>
  <div class="container-fluid p-4">
    <div class="d-flex justify-content-between">
      <h1>Manage {{ userTypeDisplay }}</h1>
      <div class="d-flex justify-content-between">
        <h1 class="mr-3">Total :{{ totalRows }}</h1>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-between pt-4">
      <div class="d-flex justify-content-between pb-1">
        <div class="d-flex justify-content-start">
          <b-button
            variant="primary"
            size="sm"
            @click="showAddUserForm"
            v-b-modal.form-add-user
          >
            <i class="fa fa-plus mr-1"></i>Add {{ userTypeDisplay }}
          </b-button>
        </div>
        <div class="d-flex justify-content-end w-30 d-inline-block mr-2">
          <b-form-input
            id="search_bar"
            size="sm"
            type="text"
            placeholder="Search..."
            v-model="searchTerms"
          ></b-form-input>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <!-- Main table element -->
        <b-table
          striped
          class="tableBorder"
          stacked="md"
          small
          show-empty
          :busy="busy"
          :items="usersList"
          :fields="resultTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="searchTerms"
          :filter-included-fields="filterOn"
          :current-page="currentPage"
          :per-page="perPage"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-white my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(index)="row">
            {{ row.index + 1 + (currentPage - 1) * perPage }}
          </template>
          <template #cell(username)="row">
            {{ row.item.username }}
            <div v-if="row.item.is_superuser">
              <b-badge variant="warning">Superuser</b-badge>
            </div>

            <div v-if="row.item.id === getCurrentUser.id">
              <b-badge variant="info">You</b-badge>
            </div>
          </template>
          <template #cell(name)="row">
            {{ row.item.name | dashForEmptyText }}
          </template>
          <template #cell(is_active)="row">
            <b-form-checkbox
              :disabled="row.item.id === getCurrentUser.id"
              v-model="row.item.is_active"
              size="lg"
              name="check-button"
              switch
              @change="userStatusHandler(row.item)"
            />
          </template>
          <template #cell(is_admin)="row">
            <div class="text-left">
              <i
                class="text-success fa fa-check-circle"
                v-if="row.item.is_admin"
              ></i>
              <i class="text-muted fa fa-times-circle" v-else></i>
            </div>
          </template>
          <template #cell(is_apiuser)="row">
            <div class="text-left">
              <i
                class="text-success fa fa-check-circle"
                v-if="row.item.is_apiuser"
              ></i>
              <i class="text-muted fa fa-times-circle" v-else></i>
            </div>
          </template>
          <template #cell(actions)="row">
            <b-button
              variant="secondary"
              size="sm"
              v-b-modal.form-update-user
              v-b-tooltip.hover
              title="Update User"
              @click="showUpdateUserForm(row.item, row.index)"
              class="mr-1"
              :disabled="disabled('update', row.item.id, row.item.is_superuser)"
            >
              <i class="fa fa-edit"></i>
            </b-button>
            <b-button
              variant="secondary"
              size="sm"
              v-b-modal.form-reset-password
              v-b-tooltip.hover
              title="Reset User Password"
              @click="showResetPasswordForm(row.item, row.index)"
              class="mr-1"
              :disabled="
                disabled('password', row.item.id, row.item.is_superuser)
              "
            >
              <i class="fa fa-key"></i>
            </b-button>
            <b-button
              variant="secondary"
              size="sm"
              v-b-modal.form-manage-permissions
              v-b-tooltip.hover
              title="Manage Permissions"
              @click="showManagePermissionsForm(row.item, row.index)"
              class="mr-1"
              :disabled="
                disabled(
                  'manage_permission',
                  row.item.id,
                  row.item.is_superuser
                )
              "
            >
              <i class="fa fa-user-cog"></i>
            </b-button>

            <b-button
              variant="danger"
              size="sm"
              @click="confirmDelete(row.item, row.index)"
              v-b-tooltip.hover
              title="Delete User"
              :disabled="disabled('delete', row.item.id, row.item.is_superuser)"
            >
              <i class="fa fa-trash-alt"></i>
            </b-button>
          </template>
        </b-table>
      </div>
      <!--pagination-->
      <div class="d-flex justify-content-between w-100" v-if="!busy && !search">
        <!-- @change="handlePerPageChange" -->
        <div class="d-flex">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
          Per Page
        </div>

        <div class="d-flex">
          <!--  @change="handlePageChange" -->
          <b-pagination
            size="sm"
            v-if="perPage !== 'all'"
            class="d-flex"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          ></b-pagination>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
      id="form-add-user"
      :title="'Add ' + userTypeDisplay"
      ref="formAddUser"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitAddForm">
        <b-form-group
          id="input-group-username"
          label="Username:"
          label-for="input-username"
        >
          <b-form-input
            id="input-username"
            v-model="formAdd.username"
            type="text"
            placeholder="Enter username"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-name"
          label="Full name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formAdd.name"
            type="text"
            placeholder="Enter name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-email"
          label="Email:"
          label-for="input-email"
        >
          <b-form-input
            id="input-email"
            v-model="formAdd.email"
            type="text"
            placeholder="Enter email"
            required
            @keyup="handleLowercase"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-password"
          label="Password:"
          label-for="input-password"
        >
          <b-form-input
            id="input-password"
            v-model="formAdd.password"
            type="password"
            placeholder="Enter password"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Role:"
          v-if="
            getCurrentScMode != 'SC_CONNECT' &&
            getCurrentScMode != 'SC_DASHBOARD'
          "
        >
          <b-form-radio
            v-for="roleOption in this.rolesList"
            :key="'role-' + roleOption.id"
            :name="roleOption.name"
            :value="roleOption.id"
            :unchecked-value="null"
            size="sm"
            v-model="formAdd.role_id"
          >
            {{ roleOption.name }}
          </b-form-radio>
        </b-form-group>

        <!--        <b-form-checkbox-->
        <!--          id="input-is-admin"-->
        <!--          v-model="formAdd.is_admin"-->
        <!--          name="is_admin"-->
        <!--          :value="true"-->
        <!--          :unchecked-value="false"-->
        <!--          size="sm"-->
        <!--        >-->
        <!--          Create this user as Admin-->
        <!--        </b-form-checkbox>-->

        <div class="text-danger" v-if="formAddErrorMessage">
          {{ formAddErrorMessage }}
        </div>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- update user -->
    <b-modal
      :title="'Update ' + userTypeDisplay"
      id="form-update-user"
      ref="formUpdateUser"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitUpdateForm">
        <b-form-group
          id="input-group-username"
          label="Username:"
          label-for="input-username"
        >
          <b-form-input
            id="input-username"
            v-model="formUpdate.username"
            type="text"
            placeholder="Enter username"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-name"
          label="Full name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formUpdate.name"
            type="text"
            placeholder="Enter name"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-email"
          label="Email:"
          label-for="input-email"
        >
          <b-form-input
            id="input-email"
            v-model="formUpdate.email"
            type="text"
            placeholder="Enter email"
            required
            @keyup="handleLowercase"
          ></b-form-input>
        </b-form-group>

        <b-form-checkbox v-model="formUpdate.is_unattended">
          Enable unattended access
          <span
            v-b-tooltip.hover
            title="Provide access to the device in unattended mode."
            ><i class="fas fa-info-circle"></i
          ></span>
        </b-form-checkbox>

        <div class="text-danger" v-if="formUpdateErrorMessage">
          {{ formUpdateErrorMessage }}
        </div>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- manage permission -->
    <b-modal
      id="form-manage-permissions"
      title="Manage User Permissions"
      ref="formManagePermissions"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitManagePermissionsForm">
        <b-form-group
          :disabled="getCurrentUser.id === formManagePermissionsID"
          :class="
            getCurrentUser.id === formManagePermissionsID ? 'text-muted' : null
          "
          label="Type:"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-checkbox
            key="is_admin"
            id="is_admin"
            name="is_admin"
            :aria-describedby="ariaDescribedby"
            :value="true"
            :unchecked-value="false"
            size="sm"
            v-model="formManagePermissions.isAdmin"
            inline
          >
            Admin
          </b-form-checkbox>

          <!--          <b-form-checkbox-->
          <!--            key="is_apiuser"-->
          <!--            id="is_apiuser"-->
          <!--            name="is_apiuser"-->
          <!--            :aria-describedby="ariaDescribedby"-->
          <!--            :value="true"-->
          <!--            :unchecked-value="false"-->
          <!--            size="sm"-->
          <!--            v-model="formManagePermissions.isApiUser"-->
          <!--            inline-->
          <!--          >-->
          <!--            API User-->
          <!--          </b-form-checkbox>-->
        </b-form-group>

        <b-form-group
          label="Role:"
          v-if="
            getCurrentScMode !== 'SC_CONNECT' &&
            getCurrentScMode !== 'SC_DASHBOARD'
          "
        >
          <b-form-radio
            v-for="roleOption in this.rolesList"
            :key="'role-' + roleOption.id"
            :name="roleOption.name"
            :value="roleOption.id"
            :unchecked-value="null"
            size="sm"
            v-model="formManagePermissions.role_id"
          >
            {{ roleOption.name }}
          </b-form-radio>
          <!--          <b-form-radio-->
          <!--            size="sm"-->
          <!--            :value="0"-->
          <!--            v-model="formManagePermissions.role_id"-->
          <!--          >-->
          <!--            None-->
          <!--          </b-form-radio>-->
        </b-form-group>

        <b-form-group
          label="Panels:"
          v-slot="{ ariaDescribedby }"
          :disabled="getCurrentUser.id === formManagePermissionsID"
          :class="
            getCurrentUser.id === formManagePermissionsID ? 'text-muted' : null
          "
        >
          <div
            v-for="option in formManagePermissionsPanelOptions"
            :key="option.id"
          >
            <b-form-checkbox
              v-if="formPermissionDisplayChecker(option.admin_only)"
              :id="option.id"
              :name="option.id"
              :value="option.id"
              :unchecked-value="null"
              size="sm"
              v-model="formManagePermissions.panel[option.id]"
              :aria-describedby="ariaDescribedby"
              inline
            >
              {{ option.name }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group
          label="Pages:"
          v-slot="{ ariaDescribedby }"
          :disabled="getCurrentUser.id === formManagePermissionsID"
          :class="
            getCurrentUser.id === formManagePermissionsID ? 'text-muted' : null
          "
        >
          <div
            v-for="option in formManagePermissionsPageOptions"
            :key="option.id"
          >
            <b-form-checkbox
              v-if="formPermissionDisplayChecker(option.admin_only)"
              :id="option.id"
              :name="option.id"
              :value="option.id"
              :unchecked-value="null"
              size="sm"
              v-model="formManagePermissions.page[option.id]"
              :aria-describedby="ariaDescribedby"
              inline
            >
              {{ option.name }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <div class="text-danger" v-if="formManagePermissionsErrorMessage">
          {{ formManagePermissionsErrorMessage }}
        </div>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- reset password -->
    <b-modal
      id="form-reset-password"
      title="Reset Password"
      ref="formResetPasswordUser"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitResetPasswordForm">
        <b-form-group
          id="input-group-password-1"
          label="Password:"
          label-for="input-password-1"
        >
          <b-form-input
            id="input-password"
            v-model="$v.formResetPassword.password_1.$model"
            :state="validateResetPassword('password_1')"
            type="password"
            placeholder="Enter password"
          ></b-form-input>
          <b-form-invalid-feedback
            class="invalid-feedback"
            id="formResetPassword.password_1_feedback"
          >
            Your password must be at least 4 - 20 character. Allowed special
            characters are "-._!@#$%^&+="
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-password-2"
          label="Repeat Password:"
          label-for="input-password-2"
        >
          <b-form-input
            id="input-password"
            v-model="$v.formResetPassword.password_2.$model"
            :state="validateResetPassword('password_2')"
            type="password"
            placeholder="Repeat password"
          ></b-form-input>
          <b-form-invalid-feedback
            class="invalid-feedback"
            id="formResetPassword.password_1_feedback"
          >
            Your password must be at least 4 - 20 character. Allowed special
            characters are "-._!@#$%^&+=".
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="text-danger" v-if="formResetPasswordErrorMessage">
          {{ formResetPasswordErrorMessage }}
        </div>
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  required,
  minLength,
  maxLength,
  between,
  helpers
} from "vuelidate/lib/validators";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import { toHandlers } from "@vue/runtime-core";

let utils = require("@/assets/global/js/utils.js");

const username_regex = helpers.regex(
  "username_regex",
  /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
);

export default {
  data: function () {
    return {
      results: [],
      resultTableFields: [
        {
          key: "index",
          label: "No.",
          sortDirection: "desc",
          thStyle: { width: "3%" }
        },
        { key: "selected", label: "", thStyle: { width: "auto" } },
        // {key: 'id', label: 'ID', sortable: true, sortDirection: 'desc'},
        {
          key: "username",
          label: "Username",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        {
          key: "is_admin",
          label: "Admin",
          sortable: true,
          sortDirection: "asc",
          thStyle: { width: "auto" }
        },
        // {
        //   key: "is_apiuser",
        //   label: "API User",
        //   sortable: true,
        //   sortDirection: "asc"
        // },
        {
          key: "is_active",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          thStyle: { width: "auto" }
        },
        { key: "actions", label: "Actions", thStyle: { width: "auto" } }
      ],
      usersList: [],
      //usersAllList: [],
      sortDesc: true,
      sortBy: "created_at",
      sortDirection: "desc",

      // table search
      searchTerms: "",
      search: false,
      busy: false,

      formAdd: {
        username: null,
        name: null,
        email: null,
        password: null,
        role_id: null
      },
      formUpdate: {
        username: null,
        name: null,
        email: null,
        is_unattended: false
      },
      formResetPassword: {
        password_1: null,
        password_2: null
      },
      formUpdateID: null,
      formResetPasswordID: null,
      formManagePermissionsID: null,
      formManagePermissionsPanelOptions: [],
      formManagePermissionsPageOptions: [],
      formManagePermissions: {
        isAdmin: false,
        isApiUser: false,
        panel: {},
        page: {},
        role_id: null
      },
      formManagePermissionsUserData: null,
      formAddErrorMessage: null,
      formUpdateErrorMessage: null,
      formManagePermissionsErrorMessage: null,
      formResetPasswordErrorMessage: null,
      currentUserID: null,
      totalRows: 0,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15],
      // pageOptions: [5, 10, 15, { value: "all", text: "All" }],

      filter: null,
      filterOn: ["username", "name", "email"],
      userRoleUpdateSuccess: true,
      userPanelsUpdateSuccess: true,
      userPagesUpdateSuccess: true,
      rolesList: []
    };
  },
  validations: {
    formResetPassword: {
      password_1: {
        minLength: minLength(4),
        maxLength: maxLength(20),
        containsSpecial: function (value) {
          return /[-._!@#$%^&+=]/.test(value);
        }
      },
      password_2: {
        minLength: minLength(4),
        maxLength: maxLength(20),
        containsSpecial: function (value) {
          return /[-._!@#$%^&+=]/.test(value);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getCurrentScMode: "session/getScMode"
    }),
    getUsers() {
      return this.$store.getters["users/getUsers"](null);
    },
    userTypeDisplay() {
      switch (this.getCurrentScMode) {
        case "SC_CONNECT":
          return "API User";
        //return "API Users";
        default:
          return "User";
        //return "Users";
      }
    }
  },
  watch: {
    $route: function () {
      if (this.$route.query.per_page === "all") {
        this.currentPage = 1;
        this.perPage = this.$route.query.per_page;
      } else {
        this.currentPage = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
        this.perPage = this.$route.query.per_page
          ? parseInt(this.$route.query.per_page)
          : 15;
      }
      this.getUsersList();
    },
    getUsers: function (n, o) {
      this.usersList = n;
    }
  },
  created() {
    if (this.$route.query.per_page === "all") {
      this.currentPage = 1;
      this.perPage = this.$route.query.per_page;
    } else {
      this.currentPage = this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1;
      this.perPage = this.$route.query.per_page
        ? parseInt(this.$route.query.per_page)
        : 15;
    }
  },
  mounted: function () {
    this.getPaginationUsersList();
    this.getUsersList();
    this.getRolesList();
  },
  methods: {
    getPaginationUsersList: function () {
      this.$store.dispatch("users/resetUser");
      let $this = this;
      let query = {};

      if (this.perPage === "all") {
        query = null;
      } else {
        query = {
          page_index: this.currentPage,
          page_size: this.perPage
        };
      }

      let API_URL = $this.getAPIServerURL + "/users/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL, query)
        .then((data) => {
          let result = data.result;
          /* if (result != undefined) {
            if (this.perPage == "all") {
              for (let key1 in result) {
                // ignore superuser
                if (!result[key1].is_superuser) {
                  if (result[key1].visitorservice_host_id == null) {
                    $this.$store.dispatch(
                      "users/addUser",
                      result[key1]
                    );
                  }
                }
              }
            } else {
              for (let key2 in result["users"]) {
                // ignore superuser
                if (!result["users"][key2].is_superuser) {
                  if (result["users"][key2].visitorservice_host_id == null) {
                    $this.$store.dispatch(
                      "users/addUser",
                      result["users"][key2]
                    );
                  }
                }
              }
            }
          } 
          if (result["total"] !== undefined) {
            this.totalRows = parseInt(result["total"]);
          } */
          if (result["page"] !== undefined) {
            this.currentPage = parseInt(result["page"]);
          }
          if (result["size"] !== undefined) {
            this.perPage = parseInt(result["size"]);
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    getUsersList: function () {
      let $this = this;
      let API_URL = $this.getAPIServerURL + "/users/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          if (result != undefined) {
            let count = 0;
            for (let key in result) {
              // ignore superuser
              if (!result[key].is_superuser) {
                if (result[key].visitorservice_host_id == null) {
                  count++;
                  $this.$store.dispatch("users/addUser", result[key]);
                }
              }
              //$this.$store.dispatch("users/addUser", result[key]);
            }
            this.totalRows = count;
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    getRolesList: function () {
      let $this = this;
      let API_URL = $this.getAPIServerURL + "/roles/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          let result = data.result;
          if (result != undefined && Array.isArray(result)) {
            for (let key in result) {
              if (result[key].name != "VMS Host") {
                this.rolesList.push(result[key]);
              }
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
        });
    },
    getUserProfile: function () {
      let $this = this;
      let API_URL = $this.getAPIServerURL + "/users/my-user/";
      const client = $this.$root.getAjaxFetchClient();

      client
        .getRequest(API_URL)
        .then((data) => {
          if (data.id != undefined) {
            let user_data = data;
            $this.$store.dispatch("session/setCurrentUser", user_data);
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    toggleAll(checked) {
      if (checked) {
        this.selectAllRows();
      } else {
        this.clearSelected();
      }
    },
    activateAll() {
      let $this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Confirm activate" + " " + $this.selected.length + " users?",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value == true) {
            for (let i = 0; i < $this.selected.length; i++) {
              this.activateUser(this.selected[i].id);
            }

            this.clearSelected();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deactivateAll() {
      let $this = this;
      this.$bvModal
        .msgBoxConfirm(
          "Confirm de-activate" + " " + $this.selected.length + " users?",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value == true) {
            for (let i = 0; i < $this.selected.length; i++) {
              this.deactivateUser(this.selected[i].id);
            }

            this.clearSelected();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    disabled(id, row_item_id, row_item_is_superuser) {
      if (
        id == "update" &&
        row_item_id == this.getCurrentUser.id &&
        row_item_is_superuser == false
      ) {
        return true;
      } else if (
        id == "delete" &&
        row_item_id == this.getCurrentUser.id &&
        row_item_is_superuser == false
      ) {
        //
        return true;
      } /* else if (
        id == "manage_permission" &&
        row_item_id == this.getCurrentUser.id &&
        row_item_is_superuser == false
      ) {
        //
        return true;
      } */ else if (
        id == "password" &&
        row_item_id == this.getCurrentUser.id &&
        row_item_is_superuser == false
      ) {
        //
        return true;
      } /* else if (
        (id == "password" ||
          id == "update" ||
          id == "delete" ||
          id == "manage_permission") &&
        row_item_is_superuser == true
      ) {
        //
        return true;
      } */ else if (
        id == "manage_permission" &&
        this.getCurrentScMode === "SC_CONNECT"
      ) {
        return true;
      }
    },

    showAddUserForm: function () {
      this.formAdd.username = null;
      this.formAdd.name = null;
      this.formAdd.email = null;
      this.formAdd.password = null;
      this.formAddErrorMessage = null;
      if (
        this.getCurrentScMode != "SC_CONNECT" &&
        this.getCurrentScMode != "SC_DASHBOARD"
      ) {
        this.formAdd.role_id = null;
      }
    },
    showUpdateUserForm: function (item, index) {
      this.formUpdate.username = item.username;
      this.formUpdate.name = item.name;
      this.formUpdate.email = item.email;
      this.formUpdate.is_unattended = item.is_unattended;
      this.formUpdateID = item.id;
      this.formUpdate.is_unattended = item.is_unattended;
      this.formUpdateErrorMessage = null;
    },
    showResetPasswordForm: function (item, index) {
      this.clearResetPasswordForm();
      this.formResetPasswordID = item.id;
      this.formResetPasswordErrorMessage = null;
    },
    showManagePermissionsForm: function (item, index) {
      this.formManagePermissionsID = item.id;
      this.formManagePermissionsErrorMessage = null;

      this.formManagePermissionsUserData = item;

      let $this = this;

      // set is_admin
      if (item.is_admin) {
        $this.formManagePermissions.isAdmin = true;
      } else {
        $this.formManagePermissions.isAdmin = false;
      }

      if (item.is_apiuser) {
        $this.formManagePermissions.isApiUser = true;
      } else {
        $this.formManagePermissions.isApiUser = false;
      }

      // roles
      if (item.role_id !== undefined || item.role_id !== null) {
        $this.formManagePermissions.role_id = item.role_id;
      }

      // get panels list
      this.$store.dispatch("psim/fetchPanelOptions").then((data) => {
        if (data.detail != undefined) {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: data.detail,
            message_type: "danger"
          });
        } else {
          for (let i = 0; i < data.length; i++) {
            if (item.panels != null && item.panels.includes(data[i]["id"])) {
              $this.formManagePermissions.panel[data[i]["id"]] = data[i]["id"];
            } else {
              $this.formManagePermissions.panel[data[i]["id"]] = null;
            }
          }
          this.formManagePermissionsPanelOptions = data;
        }
      });

      // get pages list
      this.$store.dispatch("psim/fetchPageOptions").then((data) => {
        if (data.detail != undefined) {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: data.detail,
            message_type: "danger"
          });
        } else {
          for (let i = 0; i < data.length; i++) {
            if (item.pages != null && item.pages.includes(data[i]["id"])) {
              $this.formManagePermissions.page[data[i]["id"]] = data[i]["id"];
            } else {
              $this.formManagePermissions.page[data[i]["id"]] = null;
            }
          }
          this.formManagePermissionsPageOptions = data;
        }
      });
    },
    clearResetPasswordForm: function () {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.formResetPassword = {
        password_1: null,
        password_2: null
      };
    },
    //handle
    handleLowercase() {
      if (this.formAdd.email != null) {
        this.formAdd.email = this.formAdd.email.toLowerCase();
      }

      if (this.formUpdate.email != null) {
        this.formUpdate.email = this.formUpdate.email.toLowerCase();
      }
    },
    handlePageChange(page) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      if (pageSize === "all") {
        this.$router.push({
          ...this.$route,
          query: {
            per_page: pageSize
          }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            per_page: parseInt(pageSize)
          }
        });
      }
    },
    //validation
    validateResetPassword(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.formResetPassword[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },

    onSubmitAddForm: function (e) {
      e.preventDefault();
      let $this = this;

      console.log($this.getCurrentScMode);
      if (
        ($this.getCurrentScMode == "SC_VMS" ||
          $this.getCurrentScMode == "SC_LPR") &&
        $this.formAdd.role_id == null
      ) {
        this.$bvModal.msgBoxOk("Please select a role", {
          centered: true
        });
        return;
      }

      //$this.formAdd.email = $this.formAdd.email.toLowerCase();

      // console.log(this.form);
      let API_URL = $this.getAPIServerURL + "/users/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, $this.formAdd)
        .then((data) => {
          if (data.detail != undefined) {
            this.formAddErrorMessage = data.detail;
          } else {
            // add new user to store
            $this.$store.dispatch("users/addUser", data);

            // // call fetch users
            // $this.fetchUsers();

            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text:
                "User " + data.username + " has been added successfully",
              message_type: "success"
            });
            $this.formAdd.username = null;
            $this.formAdd.name = null;
            $this.formAdd.email = null;
            $this.formAdd.password = null;
            $this.formAdd.is_admin = false;
            $this.formAddErrorMessage = null;
            $this.$refs.formAddUser.hide();
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          if (err.status == 401) {
            $this.$store.dispatch("session/logoutSession");
          }
        });
    },
    onSubmitUpdateForm: function (e) {
      e.preventDefault();
      let $this = this;
      let API_URL_unattend = "";

      if ($this.formUpdate.is_unattended == true) {
        API_URL_unattend =
          $this.getAPIServerURL +
          "/users/" +
          $this.formUpdateID +
          "/unattended/";
      } else if ($this.formUpdate.is_unattended == false) {
        API_URL_unattend =
          $this.getAPIServerURL + "/users/" + $this.formUpdateID + "/attended/";
      }
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL_unattend)
        .then((data) => {
          if (data == null) {
            let API_URL =
              $this.getAPIServerURL + "/users/" + $this.formUpdateID + "/";
            const client = $this.$root.getAjaxFetchClient();
            client
              .putRequest(API_URL, $this.formUpdate)
              .then((data) => {
                // update user to store
                if (data == null) {
                  $this.$store.dispatch("users/updateUser", {
                    id: $this.formUpdateID,
                    data: {
                      username: $this.formUpdate.username,
                      name: $this.formUpdate.name,
                      email: $this.formUpdate.email,
                      is_unattended: $this.formUpdate.is_unattended
                    }
                  });
                  $this.formUpdateID = null;
                  $this.formUpdate.username = null;
                  $this.formUpdate.name = null;
                  $this.formUpdate.email = null;
                  $this.formUpdateErrorMessage = null;
                  $this.formUpdate.is_unattended = false;
                  $this.$refs.formUpdateUser.hide();
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: "Updated user successfully",
                    message_type: "success"
                  });
                } else {
                  if (data.detail != undefined) {
                    $this.formUpdateErrorMessage = data.detail;
                  }
                }
              })
              .catch((err) => {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: err.toString(),
                  message_type: "danger"
                });
                // if (err.status == 401) {
                //   $this.$store.dispatch("session/logoutSession");
                // }
              });
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    onSubmitResetPasswordForm: function (e) {
      e.preventDefault();
      let $this = this;

      let API_URL =
        $this.getAPIServerURL +
        "/users/" +
        $this.formResetPasswordID +
        "/password/reset/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, $this.formResetPassword)
        .then((data) => {
          if (data == null) {
            $this.formResetPasswordID = null;
            $this.formResetPassword.password_1 = null;
            $this.formResetPassword.password_2 = null;
            $this.formResetPasswordErrorMessage = null;
            $this.$refs.formResetPasswordUser.hide();
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Password reset successfully",
              message_type: "success"
            });
          } else {
            if (data.detail != undefined) {
              $this.formResetPasswordErrorMessage = data.detail;
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    onSubmitManagePermissionsForm: function (e) {
      e.preventDefault();
      let $this = this;
      //check role id
      /* if ($this.formManagePermissions.role_id == null) {
        this.$bvModal.msgBoxOk("Please select an role", {
          centered: true
        });
        return;
      } */

      if (
        ($this.getCurrentScMode == "SC_VMS" ||
          $this.getCurrentScMode == "SC_LPR") &&
        $this.formManagePermissions.role_id == null
      ) {
        this.$bvModal.msgBoxOk("Please select a role", {
          centered: true
        });
        return;
      }

      let role_data = { is_admin: false, is_apiuser: false };
      if ($this.formManagePermissions.isAdmin) {
        role_data["is_admin"] = true;
      }
      if ($this.formManagePermissions.isApiUser) {
        role_data["is_apiuser"] = true;
      }
      $this.updateUserRole($this.formManagePermissionsID, role_data);

      $this.formManagePermissionsUserData.is_admin = role_data.is_admin;

      let userObj = $this.formManagePermissionsUserData;

      // let preferencesObj = userObj["preferences"];
      //
      // let roleObj;
      //
      // if (Object.prototype.hasOwnProperty.call(preferencesObj, 'role')) {
      //   roleObj = preferencesObj['role'];
      //   roleObj['id'] = $this.formManagePermissions.role;
      // } else {
      //   roleObj = {
      //     "role": {
      //       "id": $this.formManagePermissions.role
      //     }
      //   }
      // }
      // preferencesObj = {
      //   ...preferencesObj,
      //   ...roleObj,
      // };

      userObj = {
        ...userObj,
        role_id: $this.formManagePermissions.role_id
      };
      let API_URL =
        $this.getAPIServerURL + "/users/" + $this.formManagePermissionsID + "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, userObj, false)
        .then((data) => {
          if (data == null) {
            $this.$store.dispatch("users/updateUser", {
              id: $this.formManagePermissionsID,
              data: {
                role_id: $this.formManagePermissions.role_id
              }
            });
            let panels = [];
            for (let key in $this.formManagePermissions.panel) {
              let found = $this.formManagePermissionsPanelOptions.find(
                (element) => element.id == key
              );

              if ($this.formManagePermissions.panel[key] != null) {
                if (found.admin_only) {
                  if ($this.formManagePermissions.isAdmin) {
                    panels.push($this.formManagePermissions.panel[key]);
                  }
                } else {
                  panels.push($this.formManagePermissions.panel[key]);
                }
              }
            }

            $this.updateUserPanelsPermission($this.formManagePermissionsID, {
              panels: panels
            });
            let pages = [];
            for (let key in $this.formManagePermissions.page) {
              let found = $this.formManagePermissionsPageOptions.find(
                (element) => element.id == key
              );

              if ($this.formManagePermissions.page[key] != null) {
                if (found.admin_only) {
                  if ($this.formManagePermissions.isAdmin) {
                    pages.push($this.formManagePermissions.page[key]);
                  }
                } else {
                  pages.push($this.formManagePermissions.page[key]);
                }
              }
            }

            $this.updateUserPagesPermission($this.formManagePermissionsID, {
              pages: pages
            });

            if (
              $this.userRoleUpdateSuccess &&
              $this.userPanelsUpdateSuccess &&
              $this.userPagesUpdateSuccess
            ) {
              // update user to store
              $this.$store.dispatch("users/updateUser", {
                id: $this.formManagePermissionsID,
                data: {
                  is_admin: $this.formManagePermissions.isAdmin,
                  is_apiuser: $this.formManagePermissions.isApiUser,
                  panels: panels,
                  pages: pages,
                  role_id: $this.formManagePermissions.role_id
                }
              });

              $this.formManagePermissions = {
                isAdmin: false,
                isApiUser: false,
                panel: {},
                page: {},
                role_id: $this.formManagePermissions.role_id
              };

              $this.$refs.formManagePermissions.hide();

              $this.getUserProfile();
            }
          } else {
            $this.formManagePermissionsErrorMessage = data.detail;
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
        });
    },
    confirmDeleteUsers: function (e) {
      e.preventDefault();
      let $this = this;
      if ($this.selectedUsers.length > 0) {
        $this.$bvModal
          .msgBoxConfirm(
            "Confirm deleting " + $this.selectedUsers.length + " users?",
            {
              centered: true
            }
          )
          .then((value) => {
            // console.log(value);
            if (value == true) {
              for (let f in $this.selectedUsers) {
                let user_id = $this.selectedUsers[f];
                $this.deleteUser(user_id);
              }
            }
          })
          .catch((err) => {
            // An error occurred
          });
      } else {
        $this.$bvModal.msgBoxOk("No users selected", { centered: true });
      }
    },
    confirmDelete: function (user_data, index) {
      // e.preventDefault();
      let $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm deleting " + user_data.username + "?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            $this.deleteUser(user_data.id);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteUser: function (user_id) {
      let $this = this;
      let data = {
        previous_data: {}
      };
      let API_URL = $this.getAPIServerURL + "/users/" + user_id + "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL, data)
        .then((data) => {
          $this.$store.dispatch("users/deleteUser", user_id);

          // this.fetchUsers();

          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Deleted user successfully",
            message_type: "success"
          });

          // TOCLEAN
          // } else {
          //   this.$store.dispatch('session/addGlobalAlertMessage', {
          //     "message_text": data.detail,
          //     "message_type": "danger"
          //   });
          // }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    userStatusHandler: function (data) {
      let action = "";
      if (!data.is_active) {
        // ! is because the client side is already updated, but server side havent updated
        action = "deactivate";
      } else {
        action = "activate";
      }

      let $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm " + action + " " + data.username + "?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            if (!data.is_active) {
              // ! is because the client side is already updated, but server side havent updated
              this.deactivateUser(data["id"]);
            } else {
              this.activateUser(data["id"]);
            }
          } else {
            // revert to previous state
            $this.$store.dispatch("users/updateUser", {
              id: data.id,
              data: { is_active: !data.is_active }
            });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    activateUser: function (user_id) {
      let $this = this;
      let API_URL = $this.getAPIServerURL + "/users/" + user_id + "/activate/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, null)
        .then((data) => {
          $this.$store.dispatch("users/updateUser", {
            id: user_id,
            data: { is_active: true }
          });
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Activated user successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.toString(),
          //   message_type: "danger"
          // });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });

      let API_URL2 = $this.getAPIServerURL + "/users/" + user_id + "/verify/";
      client
        .postRequest(API_URL2, null)
        .then((data) => {
          //
        })
        .catch((err) => {
          // $this.$store.dispatch("session/addGlobalAlertMessage", {
          //   message_text: err.toString(),
          //   message_type: "danger"
          // });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    deactivateUser: function (user_id) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/users/" + user_id + "/deactivate/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, null)
        .then((data) => {
          $this.$store.dispatch("users/updateUser", {
            id: user_id,
            data: { is_active: false }
          });
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Deactivated user successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    resetPassword: function (user_id) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/users/" + user_id + "/password/reset/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, null)
        .then((data) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Password reset successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    formPermissionDisplayChecker: function (admin_only) {
      if (admin_only) {
        if (this.formManagePermissions.isAdmin == true) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    updateUserRole: function (user_id, data) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/permissions/user/" + user_id + "/role/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, data)
        .then((data) => {
          if (data == null) {
            // $this.$store.dispatch("session/addGlobalAlertMessage", {
            //   message_text: "Updated user type",
            //   message_type: "success"
            // });
            //$this.fetchUsers();
            data;
            $this.userRoleUpdateSuccess = true;
          } else {
            $this.userRoleUpdateSuccess = false;
            if (data.detail != undefined) {
              this.formManagePermissionsErrorMessage = data.detail;
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    updateUserPanelsPermission: function (user_id, data) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/permissions/user/" + user_id + "/panels/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, data, false)
        .then((data) => {
          if (data == null) {
            // $this.$store.dispatch("session/addGlobalAlertMessage", {
            //   message_text: "Updated user permissions",
            //   message_type: "success"
            // });
            $this.userPanelsUpdateSuccess = true;
          } else {
            $this.userPanelsUpdateSuccess = false;
            if (data.detail != undefined) {
              this.formManagePermissionsErrorMessage = data.detail;
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    updateUserPagesPermission: function (user_id, data) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL + "/permissions/user/" + user_id + "/pages/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, data, false)
        .then((data) => {
          if (data == null) {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Updated user permissions",
              message_type: "success"
            });
            $this.userPagesUpdateSuccess = true;
          } else {
            $this.userPagesUpdateSuccess = false;
            if (data.detail != undefined) {
              this.formManagePermissionsErrorMessage = data.detail;
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.toString(),
            message_type: "danger"
          });
          if (err.status == 401) {
            $this.$store.dispatch("session/logoutSession");
          }
        });
    }
  }
};
</script>
<style lang="scss">
.custom-control-input:disabled ~ .custom-control-label {
  color: grey;
}
.calendars {
  background: var(--colour-nav-bg);
}

.drp-buttons {
  background: var(--colour-nav-bg);
}

.calendar-table {
  color: var(--colour-overlay-gray-dark);
}
</style>
